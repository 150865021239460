
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class Warning extends Vue {
  private msgTypeNameList = []
  private searchInfo = {
    typeName: '',
    projectName: '',
    readState: '',
    startTime: '',
    endTime: '',
    msg: ''
  }

  private tableData = {
    loading: false,
    tr: [
      {
        label: '类别',
        prop: 'typeName',
        minWidth: '80',
        showOverflowTooltip: true
      },
      {
        label: '消息内容',
        prop: 'msg',
        minWidth: '150',
        showOverflowTooltip: true
      },
      {
        label: '项目名称',
        prop: 'projectName',
        minWidth: '100',
        showOverflowTooltip: true
      },
      {
        label: '发送时间',
        prop: 'sendTime',
        minWidth: '150',
        showOverflowTooltip: true
      },
      {
        label: '消息状态',
        prop: 'readState',
        minWidth: '100',
        showOverflowTooltip: true
      }
    ],
    data: []
  }

  private total = 0
  private page = 1
  private size = 10
  private dateArr = []

  created () {
    this.getData()
    this.getTypeNameList()
  }

  changeTime (val: any) {
    console.log('val: ', val)
    if (val && val[0] && val[1]) {
      this.searchInfo.startTime = val[0]
      this.searchInfo.endTime = val[1]
    } else {
      this.searchInfo.startTime = ''
      this.searchInfo.endTime = ''
    }
  }

  getTypeNameList () {
    this.$axios.get(this.$apis.common.selectDicByList, { dicType: 'msgTypeName' }).then(res => {
      this.msgTypeNameList = res.msgTypeName
    })
  }

  getData () {
    this.tableData.loading = true
    this.$axios.get(this.$apis.messageWarning.warningPage, {
      ...this.searchInfo,
      page: this.page,
      size: this.size
    }).then((res) => {
      this.total = res.total || 0
      this.tableData.data = res.list || []
    }).finally(() => {
      this.tableData.loading = false
    })
  }

  onRead () {
    this.$axios.post(this.$apis.messageWarning.readAllMsg, { typeId: null }).then(() => {
      this.$message({ message: '操作成功', type: 'success' })
      this.$store.dispatch('getMessageNum')
      this.getData()
    })
  }

  onSearch () {
    this.page = 1
    this.getData()
  }

  onDetail (id: string) {
    this.$router.push({
      name: 'warningDetail',
      params: { id }
    })
  }
}
